@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cinzel:wght@700&family=GFS+Neohellenic:wght@700&family=Heebo:wght@900&family=IBM+Plex+Sans:wght@700&family=Inter&family=Lora&family=Montserrat:wght@400;600;800;900&family=Roboto&family=Shadows+Into+Light&family=Space+Grotesk&family=Varela+Round&display=swap');

/*------------------------All---------------------------------------------*/
*{
  font-family: 'Inter Var', sans-serif;
}
li{
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 30px; /* change this to whatever height you want it */
}
/*------------------------header---------------------------------------------*/

#header{
  width: 100%;
  height: 100px;
  
  /* box-shadow: 4px 19px rgba(51, 51, 51, 0.096); */
}
.bar{
  visibility: hidden; 
}
.dropdown-list{
  list-style-type: none !important;
}
/*------------------------hero---------------------------------------------*/
#hero6{
  background: url('./images2/cover2.png');
  background-position: center;
  background-size: cover;
}







/* .indus-right{
  background: url('./images/industries.png');
  background-size: 100vw 100vh;
} */

/* ----------------------------------------products-------------------------------------------------- */
#products{
  /* background: url('./images/products.jpg') rgba(243, 231, 239, 0.863);
  background-position: center;
  
  background-blend-mode: multiply; */
  background-size: cover;
}


.indusimg{
  z-index: -999;
   height: 80vh;
   width: 100%;
}
/* #contactlft{
  background: url('./images/contact.png');
} */



/* --------------------------------contact------------------------------------------------- */
.contactimg{
  width: 50%;
  z-index: -999;
}
.contactimg2{
  width: 122%;
}
/*----------------------------------------- offering------------------------------------------- */
.offering-bg{
  background: url('./images2/offering-bg.png');
}






/*----------------------------- comingsoon--------------------------------------- */
.comingsoon{
  background: url('./images2/products.png');
  background-position: center;
  background-size: cover;
}
/* --------------------------------offering------------------------------------------------------- */
.offering-card:hover, .product:hover {
  background-color: #373A4A !important;
  color: white;
  cursor: pointer;
  
}
















/* --------------------------------partner animation------------------------------------------------------- */

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.brand-wheel {
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100px;
  justify-content: center;

  --animationspeed: 20s;
  --animationdelay: calc( var(--animationspeed) / 2 );
}



.brand-slide {
  flex-shrink: 0;
  
  animation-name: slidelogo;
  animation-duration: var(--animationspeed);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
  display: flex;
  justify-content: space-around;
}

.brand-slide.delay {
  transform: translateX(100%);
  animation-name: slidelogo;
  animation-delay: var(--animationdelay);
}

.brand-wheel:hover .brand-slide {
  animation-play-state: paused;
}

.logo-div {
  display: inline-flex;
  align-self: center;
  animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  padding: 0 0 0 10px;
  height: 100%;
}

@keyframes slidelogo {
  from {
      transform: translateX(0%);
  }
  to {
      transform: translateX(-25%);
  }
}

/* ----------------------------------------------scroll offset------------------------------------ */

div {
	scroll-margin-top: 70px; /* Adjust the value as needed */
}





/* ----------------------------------------------media screens------------------------------------ */
@media screen and (min-width: 350px) and (max-width: 600px){
 
  .product-inside{
    background-image: none !important;
    height: auto !important;
  }
  }
  @media screen and (min-width: 350px) and (max-width: 640px) {
  #header{
    width: 50%;
  
  }}
  @media screen and (min-width: 640px) and (max-width: 1340px) {
    #header{
      width: 30%;
    }}
  @media screen and (min-width: 350px) and (max-width: 1340px) {

    .products-button{
      text-decoration: underline;
    }
    .dropdown-heaven{
      position: absolute;
      left:-30px;
      
    }
    #header{
      position: fixed;
      top: 0px;
      height: 100vh;
      background: white;
      box-shadow: 4px 9px 19px rgba(51, 51, 51, 0.096);
      display: flex;
      flex-direction: column;
      justify-content: start;
      color: white;
      
    }
    
    .dropup{
     position: relative;
    }
    .dropdown-menulist{
      position: absolute;
      left: -170px;
      top: -40px;
    }
    .menu{
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 50vh;
      text-decoration: underline;
    }
    
  
    .header-button{
      visibility: hidden;
    }
    .bar{
      visibility: visible;
      z-index: 9999999;
    }
    #mainheader{
      z-index: 999;
    }
    #hero6{
      background: url('./images2/cover2.png');
      
      background-position: right -170px center;
      text-shadow: 0 0 black;
    }
  }











/* ------------------------2nd homepage------------------------------------------- */
#home2{
  /* background: url('./images/hero.png'); */
  /* background:linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)), url(./images/hero.png); */
  /* -webkit-clip-path: circle(60.0% at 50% 10%);
clip-path: circle(50.0% at 50% 50%); */
/* clip-path: ellipse(150% 100% at 50% 0); 
-webkit-clip-path: ellipse(150% 100% at 50% 0);  */
/* background-image: url("./images/curves.jpg"), linear-gradient(#43cea2, #185a9d); */
background-image: linear-gradient(#43cea2, #185a9d);


/* important color: */
/* background-image: linear-gradient(#112323, #43cea2); */
/* background-image:
    linear-gradient(to bottom, rgba(#185a9d, 0.52), rgba(#43cea2, 0.73)),
    url('images/curves.jpg'); */
    /* background-size: cover;
    background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.test{
  background:#8543C4;
  background: #ef4444;
  background:#059669;
}

h6 {
  font-size: 72px;
  background: -webkit-linear-gradient(#43cea2, #185a9d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


#contact2{
  background: url("./images/mountain.webp");
  background-size: cover;
  background-position: center;
} 
 #footer2{
  /* background:linear-gradient(#43cea2, #185a9d); */
  
  background:linear-gradient(#43cea2, #185a9d);
}
.services-sub2{
  /* background:linear-gradient(#43cea2, #185a9d); */
  background:linear-gradient(#43cea2, #185a9d);
}
.service-text{
  background: -webkit-linear-gradient(#43cea2, #185a9d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#group2,#industries2{
  /* background:#F7F8FC; */
  background: #F3F6FD;
  
}

.beautifulgradient{
  background-image: linear-gradient(#000C14, #F8002F);
}

.circles{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background:linear-gradient(#43cea2, #185a9d);
}

/* ------------------------tatmeenonboardingg------------------------------------------- */
.download{
  z-index: 9999;
} 

/* --------------------------------junk------------------------------------------------------- */
/* 
.logos-slides{
  animation: slides 15s infinite linear;
  display: flex;
  justify-content: space-between;
 
}
.logos{
  white-space: nowrap;
} 

@keyframes slides {
  
  from{
    transform: translateX(0);
  
  }
  to {
    transform: translateX(-100%);
  }
  
  }
  #partners{
  animation: myfirst 10s 2;
  animation-direction: myfirst infinite linear;
  
}
@keyframes myfirst {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
  
  
  
  */